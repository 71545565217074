import React from 'react';
import { Deck, Slide, Heading, Image, FlexBox, Appear, Text, Grid, Notes, UnorderedList, ListItem } from 'spectacle';

import './rainbow.css';

function App() {
  return (
    <Deck>
      <Slide>
        <Heading fontSize="30px">
          <div class="wordart blues"><span class="text">Why Stealing From Your Employer is</span></div>
          <div class="wordart rainbow"><span class="text">Cool and Good</span></div>
          <div class="wordart blues"><span class="text">Actually</span></div>
        </Heading>
      </Slide>
      <Slide>
        <Heading fontSize="h4">
          <div class="wordart horizon"><span class="text">They're probably stealing your wages!</span></div>
        </Heading>
        <FlexBox style={{ display: 'flex' }}>
          <Image src="chart.jpg" style={{ margin: '0 20px', width: "40%", maxHeight: '100%' }} />
          <Image src="wagetheft.webp" style={{ margin: '0 20px', width: "40%", maxHeight: '100%' }} />
        </FlexBox>
      </Slide>
      <Slide backgroundColor="#222222">
        <Notes>
          Your employment is an exchange of your time and energy for a wage. Your employer brings money to this exchange to pay the costs of materials and factory overheads. You bring your labor. Combined, these create a product that sells for $200. Your company takes $100 out of that value to recoup what they put in, but they also take much more on top of that and call it "profits"!
        </Notes>
        <Heading fontSize="h4" style={{ margin: '100px 0 0' }}>
          <div class="wordart purple"><span class="text">You're not getting paid for all the value you create</span></div>
        </Heading>
        <FlexBox style={{ display: 'flex' }}>
          <Appear>
            <Image src="bicycle.png" style={{ margin: '0 20px', width: "80%" }} />
            <Text>$200</Text>
          </Appear>
          <Text><span class="wordart blues"><span class="text">=</span></span></Text>
          <Appear>
            <Grid gridColumn={1}>
              <div style={{ width: '400px' }}>
                <Image src="factory.png" style={{ display: 'inline', margin: '0 20px', width: "40%" }} />
                <Image src="porky.jpg" style={{ display: 'inline', margin: '0 20px', width: "40%" }} />
              </div>
              <Text style={{ margin: '0 0 0 125px' }}>$100</Text>
            </Grid>
          </Appear>
          <Text><span class="wordart blues"><span class="text">+</span></span></Text>
          <Appear>
            <Image src="wrench.png" style={{ margin: '0 20px', width: "80%" }} />
            <Text>$100</Text>
          </Appear>
        </FlexBox>
      </Slide>
      <Slide backgroundColor="#222222">
        <Heading fontSize="h4" style={{ margin: '100px 0 0' }}>
          <div class="wordart italic-outline"><span class="text">You give your employer a free advance on your labor</span></div>
        </Heading>
        <UnorderedList>
          <Appear>
            <ListItem>You pay installments on contract and commission work received from businesses.</ListItem>
          </Appear>
          <Appear>
            <ListItem>You pay interest on loans received from banks.</ListItem>
          </Appear>
          <Appear>
            <ListItem>Your employer pays no interest on work you've provided them.</ListItem>
          </Appear>
        </UnorderedList>
      </Slide>
      <Slide>
        <Notes>
          Obviously, you can't just raid your company's bank account directly, so you'll have to get creative!
        </Notes>
        <Heading style={{ margin: '100px 0 0' }}>
          <text class="rainbow" style={{ fontSize: '80px' }}>Ways to Steal Back</text>
        </Heading>
        <FlexBox style={{ display: 'flex' }}>
          <Appear>
            <Image src="wires.png" style={{ margin: '0 20px', width: "80%", maxHeight: '100%' }} />
            <Text fontSize="20px">Pull copper from the walls</Text>
          </Appear>
          <Appear>
            <Image src="clock.png" style={{ margin: '0 20px', width: "80%", maxHeight: '100%' }} />
            <Text fontSize="20px">Poop on the clock</Text>
          </Appear>
          <Appear>
            <Image src="rev.jpg" style={{ margin: '20px 25px 0', width: "80%", maxHeight: '100%' }} />
            <Text>Revolution</Text>
          </Appear>
          <Appear>
            <Image src="snacks.png" style={{ margin: '0 20px', width: "100%", maxHeight: '100%' }} />
            <Text fontSize="14px">Shove snacks and office supplies into your backpack</Text>
          </Appear>
          <Appear>
            <Image src="timefraud.png" style={{ margin: '0 20px', width: "60%", maxHeight: '100%' }} />
            <Text>Commit time fraud</Text>
          </Appear>
        </FlexBox>
      </Slide>
    </Deck>
  );
}

export default App;
